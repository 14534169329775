import ImageTmpl from './ImageTmpl';
import RegularCardContent from './RegularCardContent';
import { CardType, CardVariation, ICardItem, ILink } from '../types';
import { getImagesFromData } from '../helpers/getImagesFromData';
import clsx from 'clsx';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IRegularCardProps {
  height?: string;
  cardType?: CardType;
  isAuthorableGrid?: boolean;
  cardColor?: boolean;
  cardVariation?: CardVariation;
  video?: boolean | string;
  data?: ICardItem;
  textAlign?: string;
  copyDictionary?: Record<string, string>;
  showInfo?: boolean;
  showAll?: boolean;
  link?: ILink;
  isOdd?: boolean;
}

const RegularCard = ({
  height,
  cardType,
  isAuthorableGrid,
  cardColor,
  cardVariation,
  video,
  data,
  textAlign,
  copyDictionary,
  showInfo,
  showAll,
  link,
  isOdd,
}: IRegularCardProps) => {
  const { theme } = useGlobalContext();
  const images = getImagesFromData(data);
  const regularCardContent = (isPlaceholder: boolean) => (
    <RegularCardContent
      cardType={cardType}
      isAuthorableGrid={isAuthorableGrid}
      cardColor={cardColor}
      cardVariation={cardVariation}
      isPlaceholder={isPlaceholder}
      video={video}
      cardImage={images.cardImage}
      data={data}
      textAlign={textAlign}
      copyDictionary={copyDictionary}
      showInfo={showInfo}
      showAll={showAll}
      link={link}
      isOdd={isOdd}
    />
  );

  return (
    <div className="cursor-pointer" style={{ height: height || '100%' }}>
      <div className="h-full">
        <div
          className={clsx(
            'card-container relative flex flex-col items-stretch justify-items-stretch h-full overflow-hidden rounded-2xl',
            cardVariation !== 'recipe' && theme ? 'text-primary' : '',
            cardVariation === 'article' &&
              'align-center flex-row pb-0 border border-[#BBC4BA] bg-white',
          )}
        >
          <ImageTmpl cardVariation={cardVariation} images={images} />
          {regularCardContent(false)}
          {!['object', 'article', 'WideSmall', 'white', 'subcategory'].includes(
            cardVariation as string,
          ) && regularCardContent(true)}
        </div>
      </div>
    </div>
  );
};

export default RegularCard;
