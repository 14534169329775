export const TimeIcon = () => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    className="inline mx-px my-0 align-baseline"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71094 4.8125L10.25 4.29688C10.3672 4.17969 10.3672 4.01562 10.25 3.89844L9.85156 3.5C9.73438 3.38281 9.57031 3.38281 9.45312 3.5L8.96094 3.99219C8.23438 3.33594 7.32031 2.91406 6.3125 2.79688V1.625H6.96875C7.10938 1.625 7.25 1.50781 7.25 1.34375V0.78125C7.25 0.640625 7.10938 0.5 6.96875 0.5H4.53125C4.36719 0.5 4.25 0.640625 4.25 0.78125V1.34375C4.25 1.50781 4.36719 1.625 4.53125 1.625H5.1875V2.79688C2.75 3.07812 0.875 5.14062 0.875 7.625C0.875 10.3203 3.05469 12.5 5.75 12.5C8.42188 12.5 10.625 10.3203 10.625 7.625C10.625 6.59375 10.2734 5.60938 9.71094 4.8125ZM5.75 11.375C3.66406 11.375 2 9.71094 2 7.625C2 5.5625 3.66406 3.875 5.75 3.875C7.8125 3.875 9.5 5.5625 9.5 7.625C9.5 9.71094 7.8125 11.375 5.75 11.375ZM6.03125 8.75C6.17188 8.75 6.3125 8.63281 6.3125 8.46875V5.28125C6.3125 5.14062 6.17188 5 6.03125 5H5.46875C5.30469 5 5.1875 5.14062 5.1875 5.28125V8.46875C5.1875 8.63281 5.30469 8.75 5.46875 8.75H6.03125Z"
      fill="#292B29"
    />
  </svg>
);
