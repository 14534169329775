import { CardVideo } from '@icons/CardVideo';
import { cardPropsByType } from '../index';
import { CardType } from '../types';
import clsx from 'clsx';

interface IVideoTmplProps {
  cardType?: CardType;
  cardColor: string;
}

const VideoTmpl = ({ cardType, cardColor }: IVideoTmplProps) => {
  const videoposition = cardPropsByType[cardType || 'default'].videoposition;

  return (
    <div
      className={clsx(
        videoposition,
        'absolute left-[calc(50%-22px)] width-[50px] height-[50px] z-[1] rounded-full',
      )}
      style={{ backgroundColor: cardColor }}
    >
      <span className="border border-black pt-[5px] px-[5px] pb-0 inline-block m-[5px] rounded-full">
        <CardVideo />
      </span>
    </div>
  );
};

export default VideoTmpl;
