import { LiquidButton } from '../../LiquidButton';
import { Text as Paragraph } from '../../Typography';
import { ICardItem, ILink } from '../types';
import { SeeMoreCardArrow } from '@icons/SeeMoreCardArrow';
import clsx from 'clsx';

interface IHiddenContentProps {
  showAll: boolean;
  data?: ICardItem;
  link?: ILink;
  textAlign?: string;
}

const HiddenContent = ({ showAll, data, link, textAlign }: IHiddenContentProps) => {
  return (
    <div
      className={
        showAll
          ? ''
          : 'mmmd:max-h-0 mmmd:text-inherit mmmd:opacity-0 mmmd:pb-0 mmmd:transition-max-h-200 mmmd:transition-opacity group-hover:max-h-[100px] group-hover:opacity-100 transition-max-h duration-[800ms] transition-opacity'
      }
    >
      {data?.button ? (
        <a
          className={clsx(
            'flex justify-center relative z-10 -mt-6',
            showAll ? '-mb-12' : '-mb-10 md:my-auto',
          )}
          href={link?.url || '/'}
          target={link?.openInNewTab ? '_blank' : undefined}
        >
          <LiquidButton
            text={data?.button as string}
            height={50}
            width={200}
            color="#A45A2A"
            textColor="#fff"
          />
        </a>
      ) : (
        !!link?.displayName && (
          <div className="text-center h-fit">
            <Paragraph
              tag="div"
              type="lead"
              className={clsx(`pt-6 inline text-base font-medium tracking-widest`, textAlign)}
            >
              {link?.displayName}
            </Paragraph>
            <span aria-hidden="true" className="ml-18">
              <div className="inline align-middle text-inherit">
                <SeeMoreCardArrow className="inline align-top" />
              </div>
            </span>
          </div>
        )
      )}
    </div>
  );
};

export default HiddenContent;
