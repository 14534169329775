import { ICardItem } from '../types';

export const getImagesFromData = (data?: ICardItem) => {
  let desktopImage = '';
  let mobileImage = '';
  let tabletImage = '';
  let cardImage = '';

  if (data?.images) {
    desktopImage = (data?.images?.desktop || data?.images?.hero) as string;
    mobileImage = (data?.images?.mobile || data?.images?.hero || data?.images?.desktop) as string;
    tabletImage = (data?.images?.tablet || data?.images?.hero || data?.images?.desktop) as string;
    cardImage = (data?.images?.mobile ||
      data?.images?.tablet ||
      data?.images?.desktop ||
      data?.images?.hero) as string;
  } else {
    // TODO: remove it once add all webp images
    desktopImage = (data?.image?.desktop ||
      data?.image?.hero ||
      (data?.image && typeof data?.image === 'string')) as string;
    mobileImage = (data?.image?.mobile ||
      data?.image?.hero ||
      data?.image?.desktop ||
      (data?.image && typeof data?.image === 'string')) as string;
    tabletImage = (data?.image?.tablet ||
      data?.image?.hero ||
      data?.image?.desktop ||
      (data?.image && typeof data?.image === 'string')) as string;
    cardImage = (data?.image?.mobile ||
      data?.image?.tablet ||
      data?.image?.desktop ||
      data?.image?.hero ||
      (data?.image && typeof data?.image === 'string')) as string;
  }

  return {
    desktopImage,
    mobileImage,
    tabletImage,
    cardImage,
    width: data?.images?.width,
    height: data?.images?.height,
    mimeType: data?.images?.mimeType,
  };
};
