export const ShefIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    className="inline mx-px my-0 align-baseline"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 1.25C9.82812 1.27344 9.21875 1.46094 8.89062 1.71875C8.32812 0.992188 7.48438 0.5 6.5 0.5C5.51562 0.5 4.64844 0.992188 4.08594 1.71875C3.75781 1.46094 3.14844 1.25 2.72656 1.25C1.48438 1.25 0.476562 2.25781 0.476562 3.5C0.476562 3.5 0.476562 3.5 0.5 3.5C0.5 4.48438 2 8.75 2 8.75V11.75C2 12.1719 2.32812 12.5 2.75 12.5H10.25C10.6484 12.5 11 12.1719 11 11.75V8.75C11 8.75 12.5 4.48438 12.5 3.5C12.5 2.28125 11.4688 1.25 10.25 1.25ZM3.125 11.375V9.5H9.875V11.375H3.125ZM9.92188 8.375H9.24219L9.5 5.23438V5.21094C9.5 5.11719 9.40625 5.02344 9.3125 5.02344L8.9375 5H8.91406C8.82031 5 8.75 5.07031 8.75 5.16406L8.49219 8.39844H6.85156V5.1875C6.85156 5.09375 6.75781 5 6.66406 5H6.28906C6.17188 5 6.10156 5.09375 6.10156 5.1875V8.375H4.48438L4.22656 5.16406C4.22656 5.07031 4.15625 5 4.03906 5L3.66406 5.02344C3.57031 5.02344 3.47656 5.11719 3.47656 5.21094V5.23438L3.73438 8.375H3.05469C2.375 6.42969 1.625 4.01562 1.625 3.5C1.60156 3.5 1.60156 3.5 1.60156 3.5C1.60156 2.89062 2.11719 2.39844 2.72656 2.39844C2.9375 2.39844 3.24219 2.49219 3.40625 2.60938L4.32031 3.3125L5 2.39844C5.35156 1.90625 5.89062 1.625 6.5 1.625C7.08594 1.625 7.625 1.90625 7.97656 2.39844L8.65625 3.3125L9.57031 2.60938C9.73438 2.49219 10.0391 2.39844 10.25 2.39844C10.8594 2.39844 11.375 2.89062 11.375 3.5C11.3516 4.01562 10.625 6.42969 9.92188 8.375Z"
      fill="#292B29"
    />
  </svg>
);
