import { StarIcon } from '@icons/StarIcon';
import { Text as Paragraph } from '../../Typography';
import { TimeIcon } from '@icons/TimeIcon';
import { ShefIcon } from '@icons/ShefIcon';
import { CardVariation, IInformation } from '../types';

interface IInfoTmplProps {
  information?: IInformation;
  cardVariation?: CardVariation;
  copyDictionary?: Record<string, string>;
}

const InfoTmpl = ({ information, cardVariation, copyDictionary }: IInfoTmplProps) => {
  return (
    <div className="uppercase text-center text-opacity-75 tracking-widest mb-5">
      <div>
        {!!information?.rating && information?.rating !== '0.0' && (
          <span>
            <StarIcon />
            <Paragraph tag="span" type="sm" className="inline-block ml-1">
              {parseFloat(information.rating).toFixed(1)}
            </Paragraph>
          </span>
        )}
        {!!information?.rating &&
          information?.rating !== '0.0' &&
          !!information?.totalTime &&
          cardVariation !== 'tipsAndTricks' && (
            <span className="inline-block w-1 h-1 bg-black rounded my-[3px] mx-2.5" />
          )}
        {!!information?.totalTime && cardVariation !== 'tipsAndTricks' && (
          <span>
            <TimeIcon />
            <Paragraph tag="span" type="sm" className="inline-block ml-1">
              {information.totalTime}
            </Paragraph>
          </span>
        )}
      </div>

      {!!copyDictionary &&
        information?.difficulty !== undefined &&
        cardVariation !== 'tipsAndTricks' && (
          <span>
            <ShefIcon />
            <Paragraph tag="span" type="sm" className="inline-block ml-1">
              {copyDictionary[information.difficulty]}
            </Paragraph>
          </span>
        )}
    </div>
  );
};

export default InfoTmpl;
