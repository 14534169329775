import NextImage from '../../NextImage';
import { CardType, ICardItem, ILink } from '../types';
import HiddenContent from './HiddenContent';
import { Text as Paragraph, Title } from '../../Typography';
import CurveSvg from './CurveSvg';
import clsx from 'clsx';

interface IProductCardProps {
  data?: ICardItem;
  link?: ILink;
  cardImage: string;
  cardColor?: boolean;
  cardType?: CardType;
  textAlign?: string;
  isOdd?: boolean;
}

const ProductCard = ({
  isOdd,
  data,
  cardImage,
  cardColor,
  cardType,
  link,
  textAlign,
}: IProductCardProps) => {
  return (
    <div className="cursor-pointer h-[400px]">
      <div
        className={
          'card-container relative flex flex-col items-stretch justify-items-stretch h-full overflow-hidden rounded-2xl'
        }
      >
        <div className="product-card-image z-[9] relative w-[190px] h-[200px] flex justify-center items-center ml-[50%] -translate-x-1/2 origin-center group-hover:mmmd:translate-x-[-50%] group-hover:mmmd:scale-75 transition-transform duration-[400ms]">
          <NextImage
            src={cardImage}
            alt=""
            width={190}
            height={250}
            mimeType={data?.images?.mimeType}
            preferredWidth={800}
            className="w-full h-full object-contain"
          />
        </div>
        <div
          className={clsx(
            'w-full px-[19px] text-[color:var(--color-accent, inherit)] rounded-b-2xl border-x border-b border-x-[#BBC4BA] border-b-[#BBC4BA] border-t-0 pt-[52px] pb-[30px] group-hover:border-0 group-hover:text-[color:var(--color-accent)] absolute bottom-0',
            cardColor
              ? 'group-hover:bg-gray'
              : isOdd
                ? 'group-hover:bg-secondary'
                : 'group-hover:bg-cake',
          )}
        >
          <div className="relative">
            <CurveSvg
              cardVariation={'product'}
              cardColor={cardColor}
              cardType={cardType}
              isOdd={isOdd}
            />
            <Paragraph
              tag="div"
              type="lead"
              className="text-darkgray text-opacity-75 h-[18px] truncate text-center mb-2 mt-[22px]"
            >
              {data?.category}
              {!!(data?.category && data?.subCategory) && <span className="px-2">/</span>}
              {data?.subCategory}
            </Paragraph>
            {data?.title && (
              <Title
                tag="h2"
                type="xxs"
                className="text-center tracking-xs h-[93px] overflow-hidden flex justify-center line-clamp-3 text-accent"
              >
                {data.title}
              </Title>
            )}
            <HiddenContent showAll={false} textAlign={textAlign} link={link} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
