import { useCallback, useEffect, useState } from 'react';
import NextImage from '../../NextImage';
import { CardVariation } from '../types';

interface IImageTmplProps {
  cardVariation?: CardVariation;
  images?: {
    desktopImage?: string;
    mobileImage?: string;
    tabletImage?: string;
    cardImage?: string;
    width?: number | `${number}`;
    height?: number | `${number}`;
    mimeType?: string;
  };
}

const ImageTmpl = ({ cardVariation, images }: IImageTmplProps) => {
  const [imageSrc, setImageSrc] = useState('');

  const { desktopImage, tabletImage, mobileImage, cardImage, width, height, mimeType } =
    images || {};

  const getImageSrc = useCallback(() => {
    if (typeof window === 'undefined') return '';

    const { innerWidth } = window;

    if (innerWidth >= 1024 && desktopImage) {
      return desktopImage;
    }
    if (innerWidth >= 700 && tabletImage) {
      return tabletImage;
    }
    if (innerWidth > 320 && innerWidth < 700 && mobileImage) {
      return mobileImage;
    }

    return '';
  }, [desktopImage, tabletImage, mobileImage]);

  useEffect(() => {
    setImageSrc(getImageSrc());
  }, [getImageSrc]);

  if (cardVariation === 'subcategory' && cardImage) {
    return (
      <div className="z-[9] relative w-[230px] h-[230px] flex justify-center items-center origin-center ml-[50%] -translate-x-2/4 transition-transform duration-[400ms]">
        <NextImage
          src={cardImage}
          mimeType={mimeType}
          alt="Subcategory image"
          fill
          preferredWidth={800}
          className="w-full h-full object-contain"
        />
      </div>
    );
  }

  // Articles don't display an image. It only shows an empty card.
  if (cardVariation === 'article') {
    return <div className="grow rounded-t-2xl overflow-hidden w-full relative"></div>;
  }

  if (
    (['recipe', 'tipsAndTricks', 'white', 'WideSmall', 'content', 'articleImage'].includes(
      cardVariation || '',
    ) &&
      imageSrc) ||
    imageSrc === ''
  ) {
    return (
      <div className="h-[80%] rounded-t-2xl overflow-hidden w-full relative">
        <NextImage
          src={imageSrc}
          alt=""
          fill
          width={width}
          height={height}
          className="object-cover"
          preferredWidth={800}
          mimeType={String(mimeType)}
        />
      </div>
    );
  }
  return null;
};

export default ImageTmpl;
