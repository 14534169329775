export const StarIcon = () => (
  <svg
    width="10"
    height="10"
    xmlns="http://www.w3.org/2000/svg"
    className="align-baseline mx-px my-0 inline"
    viewBox="0 0 55.87 55.87"
  >
    <path d="M55.82 21.58a1 1 0 00-.81-.68l-18.09-2.63-8.09-16.4a1 1 0 00-1.8 0l-8.08 16.4L.85 20.9a1 1 0 00-.55 1.7l13.1 12.76-3.1 18.02a1 1 0 001.45 1.06l16.18-8.51 16.18 8.5a1 1 0 001.45-1.05l-3.09-18.02 13.1-12.76a1 1 0 00.25-1.02z" />
  </svg>
);
