export const CardVideo = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="has video"
    className="align-baseline inline my-0 mx-px"
  >
    <path
      d="M11.125 6H4.35156C3.74219 6 3.25 6.51562 3.25 7.125V13.8984C3.25 14.5078 3.74219 15 4.35156 15H11.125C11.7344 15 12.25 14.5078 12.25 13.8984V7.125C12.25 6.51562 11.7344 6 11.125 6ZM15.5547 6.89062L13 8.67188V12.3516L15.5547 14.1328C16.0469 14.4609 16.75 14.1094 16.75 13.5234V7.5C16.75 6.91406 16.0469 6.5625 15.5547 6.89062Z"
      fill="#292B29"
    />
  </svg>
);
